import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Bundle, BundleEntry, CarePlan, Condition, Patient, ServiceRequest, Task } from 'fhir/r4';
import { BehaviorSubject, tap, combineLatest, catchError, filter, map, Observable, of, Subject, switchMap, takeUntil, forkJoin, retry } from 'rxjs';
import { FhirPatientResponse } from '../shared/models/fhir/patient/response/fhir-patient-response';
import { BaseService } from './base-api.service';
import { AddPatientModalFormValue, ExtendedPatient, ImportDataModalPayload } from '../models/patient.model';
import {
  MapPatientIdsToConditionServiceRequestPayload,
  MapViewModelToEnrollPatientToProgramApi,
  MapViewModelToAddPatientApi,
  MapPatientToExtendedPatient,
  GetPatientIdsAsString,
  MapUpdatePatientDataToPatchModel,
  MapPatientProgramEnrollmentTaskToReady,
  MapPatientProgramEnrollmentTaskToCompleted,
  MapCarePlansToPatientIds,
  GetPatientIdsAsArray
} from '../mappers/patient.mapper';
import { BundleGetRequest, MultiBundleGetRequest, MapBundledRequestResponseToBundle, MapBundleToResourceArray, MapBundleToResourceArrays, MapBundleToSingleResourceArray, MapMultiBundlesToResourceArrays } from '../mappers/shared.mapper';
import { DefaultResourceQueryCount, FhirResourceType } from '../config/app.config';
import { MsalBroadcastService } from '@azure/msal-angular';
import { AuthenticationResult, EventMessage, EventType } from '@azure/msal-browser';
import { IIdTokenClaims, IIdTokenClaimsParsed } from '../shared/interfaces/id-token-claims';
import { parseIdTokenClaims } from '../utils/shared.util';
import { MapPatientModelToSideCarUser, MapSidecarUserQueryResponse, MapSidecarUserResponse } from '../mappers/sidecar-user.mapper';
import { ICreateSidecarUserResponse } from '../models/create-sidecar-user.model';

@Injectable({
  providedIn: 'root'
})
export class PatientService extends BaseService {
  unsubscribe$ = new Subject<void>();
  initiateAddPatient$ = new Subject<void>();
  addedPatient$ = new BehaviorSubject<FhirPatientResponse | null>(null);

  /**
   * To set patient name and email in calcom embed popup
   */
  currentPatient = new BehaviorSubject<FhirPatientResponse | null>(null);

  /**
   * used to broadcast the sidecar user response when a patient is created.
   */
  newSidecarUser$ = new BehaviorSubject<ICreateSidecarUserResponse | null>(null);

  /**
   * Check editCarePlanForActivePatient$ logic in AppComponent
   */
  carePlans$ = new BehaviorSubject<CarePlan[] | null>(null);

  /**
   *  used in addPatient to emit patient enrollment task, 
   *  which will be later updated as completed after validic data is imported.
   */
  patientEnrollmentTask$ = new BehaviorSubject<Task | null>(null);

  /**
   * Used to multicast Active patient response to other components like
   * patient-navigation-header component to listen for count of active patients
   * and also to reduce number of http api calls made !
   */
  activePatientListing$ = new BehaviorSubject<ExtendedPatient[] | null>(null);

  /**
   * Used to multicast pending patient response and count to other components
   */
  pendingPatientListing$ = new BehaviorSubject<ExtendedPatient[] | null>(null);

  /**
   * Used to multicast inactive patient response and count to other components
   */
  inactivePatientListing$ = new BehaviorSubject<Patient[] | null>(null);

  /**
   * To refresh respective patient listing based on workflow completion
   */
  refreshActivePatientListing$ = new Subject<void>();
  refreshPendingPatientListing$ = new Subject<void>();
  refreshInactivePatientListing$ = new Subject<void>();

  /**
   * To broadcast event to trigger and open import data dialog 
   */
  importDataInitiated$ = new BehaviorSubject<ImportDataModalPayload | null>(null);

  /**
   * To broadcast and keep track of import data status, whether import data 
   * was successfull or not
   */
  importDataSuccess$ = new BehaviorSubject<boolean>(false);

  idTokenClaims!: IIdTokenClaimsParsed;

  /**
   * used to set patient name under patient overview title
   */
  updatePatientInformation = new Subject<void>();

  constructor(
    private http: HttpClient,
    private msalBroadcastService: MsalBroadcastService
  ) {
    super();
    this.msalBroadcastService.msalSubject$.pipe(
      filter((msg: EventMessage) => msg.eventType === EventType.ACQUIRE_TOKEN_SUCCESS),
      takeUntil(this.unsubscribe$)
    ).subscribe({
      next: (value: EventMessage) => {
        const authResult = value.payload as AuthenticationResult;
        this.idTokenClaims = parseIdTokenClaims(authResult.idTokenClaims as IIdTokenClaims);
      }
    });
  }

  addPatient(addPatientModalFormValue: AddPatientModalFormValue): Observable<Bundle> {
    const mappedPayload = MapViewModelToAddPatientApi(addPatientModalFormValue);
    return this.http.post<Bundle>(`${this.FHIR_BASE}`, mappedPayload)
      .pipe(
        tap((bundleResponse: any) => {
          // Get the patient response object!
          const patient = bundleResponse.entry.find((item: any) => item.resource.resourceType === "Patient").resource;
          this.addedPatient$.next(FhirPatientResponse.makeModel(patient));
          const enrollmentTask = bundleResponse.entry.find((item: any) => item.resource.resourceType === "Task").resource;
          this.patientEnrollmentTask$.next(enrollmentTask);
        })
      );
  }

  getPatients(): Observable<Bundle<Patient>> {
    return this.http.get<Bundle<Patient>>(`${this.FHIR_BASE}/Patient`);
  }

  /**
   * For listing patients in active tab:
   * 1. Retrieve active careplans and associated patients from first API call
   * 2. Use patient ids of retrieved patients in the first API call to retrieve related conditions & service requests in second POST API call
   * 3. Aggragate patient data (careplans, conditions, service requests) to display active patient listing
   * 
   * Todo: Data related to Ocean Referral put in backlog for now
   * referredFrom data of each active patient to be extracted from serviceRequest payload
   * Currently PractitionerRole as requester is found in json payload emitted from Ocean Referral
   */
  getActivePatientListing(): Observable<ExtendedPatient[]> {
    // retrieves careplans and related patients
    return this.getCarePlansPatients()
      .pipe(
        switchMap((bundle: Bundle<CarePlan | Patient>) => {
          const { resource1: carePlans, resource2: patients } = MapBundleToResourceArrays<CarePlan, Patient>(bundle, FhirResourceType.CarePlan, FhirResourceType.Patient);
          // Retrieves conditions, service requests of patients returned in first API call
          return this.getPatientConditionsServiceRequestsTasks(GetPatientIdsAsString(patients))
            .pipe(
              map((bundle: Bundle<Bundle<Condition | ServiceRequest>>) => ({
                bundle,
                carePlans,
                patients
              })),
            )
        }),
        switchMap(({
          bundle,
          carePlans,
          patients
        }: {
          bundle: Bundle<Bundle<Condition | ServiceRequest>>,
          carePlans: CarePlan[],
          patients: Patient[]
        }) => {
          const { resource1: conditions, resource2: serviceRequests, resource3: tasks } = MapMultiBundlesToResourceArrays<Condition, ServiceRequest, Task>(bundle);
          // Extend all patients with program name (carePlan) & non-fhir properties: referred from (from Ocean Referral JSON), diagnosis (Condition)
          const extendedPatients: ExtendedPatient[] = MapPatientToExtendedPatient(patients, carePlans, conditions, serviceRequests, tasks);
          this.activePatientListing$.next(extendedPatients); // Multicast the result to other components like patient-navigation-header component to listen for count
          return of(extendedPatients);
        }),
        catchError(() => of([])),
      );
  }

  /**
   * 1st API call for active patient listing
   * @returns Bundle including active careplans & associated patients
   */
  private getCarePlansPatients(): Observable<Bundle<CarePlan | Patient>> {
    return this.http.get<Bundle<CarePlan | Patient>>(`${this.FHIR_BASE}/CarePlan?status=active&_include=CarePlan:patient&_count=${DefaultResourceQueryCount}&_total=accurate`);
  }

  /**
   * 2nd API call for active patient listing
   * @param patientIds Comma separated patient ids
   * @returns Bundle - bundle.entry[0].resource.entry for Condition, bundle.entry[1].resource.entry for ServiceRequest
   */
  private getPatientConditionsServiceRequestsTasks(patientIds: string): Observable<Bundle<Bundle<Condition | ServiceRequest>>> {
    const mappedPayload = MapPatientIdsToConditionServiceRequestPayload(patientIds);
    return this.http.post<Bundle<Bundle<Condition | ServiceRequest>>>(`${this.FHIR_BASE}/`, mappedPayload);
  }

  getPatientById(id: string): Observable<FhirPatientResponse> {
    // todo: verify and update url to return patient details based on patient id
    // todo: return the FHIR patient as mapped patient object 
    return this.http.get<Patient>(`${this.FHIR_BASE}/Patient/${id}`).pipe(
      map((patient: any) => {
        return FhirPatientResponse.makeModel(patient)
      })
    );
  }

  // Todo: server side search feature
  searchPatients({ category, query }: { category: string, query: string }): Observable<Bundle<Patient>> {
    const headers = new HttpHeaders()
      .append('Content-Type', 'application/x-www-form-urlencoded');
    const queryString = `?${category}=${query}`;
    return this.http.get<Bundle<Patient>>(`${this.FHIR_BASE}/Patient${queryString}`, { headers });
  }

  updatePatientProgramEnrollmentTask(patientId: string, task: Task): Observable<any> {
    // #R2-527 - need to pass task resrc to keep input source & destination of patient
    const mappedPayload = MapPatientProgramEnrollmentTaskToCompleted(patientId, task);
    return this.http.put<Task>(`${this.FHIR_BASE}/Task/${task.id}`, mappedPayload);
  }

  updatePatient(
    patientId: string,
    existingPatient: FhirPatientResponse,
    data: Partial<AddPatientModalFormValue>,
    conditionId: string | null
  ): Observable<any> {
    const bundlePayload = MapUpdatePatientDataToPatchModel(patientId, existingPatient, data, conditionId);
    return this.http.post(`${this.FHIR_BASE}`, bundlePayload);
  }

  getPatientConditions(patientId: string): Observable<any> {
    const queryString = `?subject:Patient=${patientId}&clinical-status=active&category=problem-list-item`;
    return this.http.get(`${this.FHIR_BASE}/Condition${queryString}`, {
      observe: "body"
    });
  }

  /**
   * After creating a new patient with patient program enrollment task with draft status
   * change status of task from draft to ready
   * @param patientProgramEnrollmentTask patient program enrollment task retrieved from FHIR response after creating a new patient
   * @returns FHIR Task resource
   */
  updatePatientProgramEnrollmentTaskStatus(patientId: string, taskId: string): Observable<Task> {
    const mappedPayload = MapPatientProgramEnrollmentTaskToReady(patientId, taskId);
    return this.http.put<Task>(`${this.FHIR_BASE}/Task/${taskId}`, mappedPayload);
  }

  /**
   * Note: patientProgramEnrollmentTask should be in draft status for newly created patients
   * @param enrollPatientToProgram 
   * @returns Task
   */
  enrollPatientToProgram(enrollPatientToProgram: any): Observable<Bundle> {
    const mappedPayload = MapViewModelToEnrollPatientToProgramApi(enrollPatientToProgram);
    return this.http.post<Bundle>(`${this.FHIR_BASE}`, mappedPayload);
  }

  /**
   * Gets careplans & patients with completed status from first API call
   * From the patient ids (allPatientIds) extracted from all the patient resources in the first API call,
   * get task resources in in-progress status and respective patients  using second API call
   * Patient resources available in the second API call response should be omitted from allPatientIds (filteredPatientIds)
   * Using filteredPatientIds call third API call to fetch patients with active careplans
   * Patient ids in the third API call should be omitted from filteredPatientIds (nonActivePatientIds)
   * Filter patient resources in the first API call using nonActivePatientIds to retrieve inactive patient listing
   * @returns 
   */
  getInactivePatientListing(): Observable<ExtendedPatient[]> {
    // added sort param to get last enrolled program
    return this.http.get<Bundle<CarePlan | Patient>>(`${this.FHIR_BASE}/CarePlan?status=completed&_total=accurate&_include=CarePlan:patient&_count=${DefaultResourceQueryCount}&_sort=-_lastUpdated`)
      .pipe(
        switchMap((bundle: Bundle<CarePlan | Patient>) => {
          const { resource1: carePlans, resource2: patients } = MapBundleToResourceArrays<CarePlan, Patient>(bundle, FhirResourceType.CarePlan, FhirResourceType.Patient);
          const allPatientIds: string[] = MapCarePlansToPatientIds(carePlans);
          return combineLatest([
            of(carePlans),
            of(patients),
            of(allPatientIds),
            this.getPatientTasks(allPatientIds.join(','))
          ]);
        }),
        switchMap(([carePlans, patients, allPatientIds, getPatientTasksBundle]) => {
          // patients with tasks in in-progress status should be omitted
          const patientsWithInProgressTasks: Patient[] = MapBundleToSingleResourceArray<Patient>(MapBundledRequestResponseToBundle<Patient | Task>(getPatientTasksBundle), FhirResourceType.Patient);
          const patientIdsWithInProgressTasks: string[] = GetPatientIdsAsArray(patientsWithInProgressTasks);
          // filteredPatientIds = patients with no in-progress task resource
          const filteredPatientIds = allPatientIds.filter((patientId: string) => !patientIdsWithInProgressTasks.includes(patientId));
          return combineLatest([
            of(carePlans),
            of(patients),
            of(filteredPatientIds),
            this.getPatientsWithActiveCarePlans(filteredPatientIds),
          ]);
        }),
        map(([carePlans, patients, filteredPatientIds, getPatientsWithActiveCarePlansBundle]) => {
          const activePatients: Patient[] = MapBundleToResourceArray<Patient>(MapBundledRequestResponseToBundle(getPatientsWithActiveCarePlansBundle));
          const activePatientIds: string[] = GetPatientIdsAsArray(activePatients);
          const nonActivePatientIds = filteredPatientIds.filter((id: string) => !activePatientIds.includes(id));
          const inactivePatients: Patient[] = patients.filter(({ id = '' }: Patient) => id && nonActivePatientIds.includes(id));
          return MapPatientToExtendedPatient(inactivePatients, carePlans, [], []);
        }),
        tap((patients: ExtendedPatient[]) => { this.inactivePatientListing$.next(patients) })
      );
  }

  getPatientTasks(patientIds: string): Observable<Bundle> {
    const payload = BundleGetRequest(`/Task?status=in-progress&code:text=Patient program enrolment&patient=${patientIds}&_include=Task:patient&_elements=id&_count=1000`);
    return this.http.post<Bundle<Task | Patient>>(this.FHIR_BASE, payload);
  }

  getPatientsWithActiveCarePlans(patientIds: string[]): Observable<Bundle> {
    const payload = BundleGetRequest(`/Patient?_id=${patientIds.join(',')}&_has%3ACarePlan%3Apatient%3Astatus=active`);
    return this.http.post<Bundle<Patient>>(this.FHIR_BASE, payload);
  }

  /**
   * For listing patients in pending tab
   * 1. Retrieve Task resource status not completed (draft, in progress and ready) & Patients
   * 2. Use patient ids of retrieved patients in the first API call to retrieve related Condition, CarePlan & ServiceRequest in second GET API call
   * 
   * Todo: Data related to Ocean Referral put in backlog for now
   * referredFrom data of each pending patient to be extracted from serviceRequest payload
   */
  getPendingPatientListing(): Observable<ExtendedPatient[]> {
    return this.getTasksPatients()
      .pipe(
        switchMap((bundle: Bundle<Task | Patient>) => {
          const { resource1: tasks, resource2: patients } = MapBundleToResourceArrays<Task, Patient>(bundle, FhirResourceType.Task, FhirResourceType.Patient);
          return this.getPatientConditionsCarePlansServiceRequests(GetPatientIdsAsString(patients))
            .pipe(
              map((bundle: any) => ({
                bundle,
                tasks,
                patients
              }))
            )
        }),
        switchMap(({
          bundle,
          tasks,
          patients
        }: {
          bundle: Bundle<any>,
          tasks: Task[],
          patients: Patient[]
        }) => {
          // Fixed issue #R2-1366 - Make it multi bundle resource for request
          const { resource1: conditions, resource2: serviceRequests, resource3: carePlans } = MapMultiBundlesToResourceArrays<Condition, ServiceRequest, CarePlan>(bundle);
          // Extend all patients with program name (carePlan) & non-fhir properties: referred from (from Ocean Referral JSON), diagnosis (Condition)
          const extendedPatients: ExtendedPatient[] = MapPatientToExtendedPatient(patients, carePlans, conditions, serviceRequests, tasks);
          this.pendingPatientListing$.next(extendedPatients);
          return of(extendedPatients);
        }),
        catchError(() => of([])),
      );
  }

  /**
   * First API call for pending patient listing
   * @returns Bundle containing resourceType - Task, Patient
   */
  private getTasksPatients(): Observable<Bundle<Task | Patient>> {
    return this.http.get<Bundle<Task | Patient>>(`${this.FHIR_BASE}/Task?code:text=Patient program enrolment&status:not=completed&_include=Task:patient&_count=${DefaultResourceQueryCount}&_total=accurate`);
  }

  /**
   * Second API call for pending patient listing
   * @param patientIds Comma separated patient ids
   * @returns Bundle containing resourceType - Condition, CarePlan, ServiceRequest
   */
  private getPatientConditionsCarePlansServiceRequests(patientIds: string): Observable<Bundle> {
    // Fixed issue #R2-1366 - Make it multi bundle resource payload
    const payload = MultiBundleGetRequest(
      [
        FhirResourceType.Condition,
        FhirResourceType.ServiceRequest,
        FhirResourceType.CarePlan
      ].map(resrc => `/${resrc}?subject=${patientIds}&_total=accurate&_count=${DefaultResourceQueryCount}`)
    );
    return this.http.post<Bundle<Condition | CarePlan | ServiceRequest>>(`${this.FHIR_BASE}`, payload);
  }

  /**
   * Get patient program enrollment task of pending patient to determine its status
   * @param patientId 
   * @returns Task
   */
  getPatientTask(patientId: string): Observable<Bundle<Patient | Task>> {
    return this.http.get<Bundle<Patient | Task>>(`${this.FHIR_BASE}/Task?patient:Patient._id=${patientId}&code:text=Patient program enrolment&_include=Task:patient&_count=1000`);
  }

  /**
   * Create a user for the newly created patient in sidecar data service.
   * @param patientId FHIR patient id
   * @param addPatientModalOutput Patient details, refer AddPatientModalFormValue interface
   * @returns 
   */
  createSidecarUser(patientId: string, patientDetails: any, organizationId: string): Observable<ICreateSidecarUserResponse> {
    const sidecarUser = MapPatientModelToSideCarUser(patientId, organizationId, patientDetails);
    console.log("Sidecar user create request:"+JSON.stringify(sidecarUser));
    return this.http.post(`${this.SIDECAR_BASE}/users`, sidecarUser)
      .pipe(
        tap((sidecarResponse: any) => {
          this.newSidecarUser$.next(MapSidecarUserResponse(sidecarResponse))
        }),
        map((sidecarResponse) => MapSidecarUserResponse(sidecarResponse))
      );
  }

  /**
   * Used in guards, also to verify if a given patient is active
   * @param patientId patient fhir id
   * @returns Observable<boolean> 
   */
  isPatientActive(patientId: string): Observable<boolean> {
    return this.http.get(`${this.FHIR_BASE}/CarePlan?patient:Patient._id=${patientId}&status=active`)
      .pipe(
        map((response: any) => response.entry?.length ? true : false)
      )
  }

  /**
   * Get the FHIR patient for the given email id
   * @param email email id of patient
   */
  getPatientByEmail(email: string) {
    return this.http.get(`${this.FHIR_BASE}/Patient?email=${email}`);
  }

  /**
   * Gets the status of patient for the given fhir patient id.
   * @param fhirId - string FHIR id of the patient
   */
  getPatientStatuses = (fhirId: string): Observable<{ careplans: CarePlan[]; tasks: Task[] }> => {
    return this.http.get(`${this.FHIR_BASE}/CarePlan?patient:Patient._id=${fhirId}&status=active,completed&_sort=-_lastUpdated&_count=1000`)
      .pipe(
        switchMap((response: any) => {
          let entries = []
          if (response?.entry?.length) {
            entries = response.entry;
          }
          return forkJoin([
            of(entries),
            this.http
              .get(`${this.FHIR_BASE}/Task?_total=accurate&code:text=Patient program enrolment&subject=${fhirId}&status:not=completed`)
              .pipe(
                map((response: any) => response?.entry?.length ? response?.entry : [])
              )
          ]).pipe(
            retry(3)
          );
        }),
        switchMap(([careplans, tasks]) => {
          return of({
            careplans: careplans.map((careplan: BundleEntry) => careplan.resource),
            tasks: tasks.map((task: BundleEntry) => task.resource)
          })
        })
      )
  }

  getSidecarPatient = (email: string) => {
    return this.http.get<Array<any>>(`${this.SIDECAR_BASE}/users?email=${email}`)
      .pipe(
        map((sidecarResponse: Array<any>) => sidecarResponse.map(MapSidecarUserQueryResponse))
      );
  }

  /**
   * Used to check the active ihealth patient
   */
  hasActiveIHealthPatient(emailId: string, patientId?: string): Observable<boolean> {
    return this.http.get<Bundle<CarePlan>>(`${this.FHIR_BASE}/CarePlan?patient:Patient.email=${emailId}&_total=accurate&status=active`)
    .pipe(
      map((response) => {
        if (!response.total) {
          return false
        }
        if (patientId) {
          if (!response.entry) {
            return false
          }
          return response.total > 0 && response.entry.filter((entry: BundleEntry<CarePlan>) => entry.resource?.subject?.reference !== `Patient/${patientId}`).length > 0;
        }
        else {
          return response.total > 0;
        }
      })
    )
  }
}
